import { graphql } from 'gatsby';
import * as React from "react"
import Layout from '~/components/Layout';
import Hero from '~/components/Hero';
import TwoColumns from '~/components/TwoColumns';
import Faqs from '~/components/Faqs';
import FlexibleContent from '~/components/FlexibleContent';
import SEO from 'gatsby-plugin-wpgraphql-seo';

export default function defaultPage({ data: { wpPage } }) {
  return (
    <Layout>
      {/* <SEO post={wpPage} /> */}
      <Hero />
      <TwoColumns />
      <Faqs />
      {/* <FlexibleContent content={wpPage.page.page.content} /> */}
    </Layout>
  )
}


export const defaultPageQuery = graphql`
  query defaultPagePageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      id
    }
  }
`
