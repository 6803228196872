import React from 'react';
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo';
import useSiteInfoAssets from '~/hooks/useSiteInfoAssets'
import Footer from '~/components/Footer';
import Header from '~/components/Header';

export default function Layout({ children}) {
  const seo = useSiteInfoAssets();

  return (
    <SEOContext.Provider value={{ global: seo }}>
      <Header />
      <main>{children}</main>
      <Footer/>
    </SEOContext.Provider>
  );
}
